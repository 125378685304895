<script lang="ts" setup>
import type { ProductType } from '~/api/common';

defineProps<{
  products: ProductType[];
  title: string;
}>();
</script>

<template>
  <BaseSliderSection
    v-if="products.length"
    class="productSimilarProducts"
    slider-class="productSimilarProducts__slider"
    navigation-class="productSimilarProducts__navigation"
    :list="products"
    :slides-per-view="{ tablet: 2, desktop: 4 }"
    mobile-grid
    with-padding-top
  >
    <template #title>
      {{ title }}
    </template>

    <template #slide="{ item }">
      <ProductCard :product="item" />
    </template>
  </BaseSliderSection>
</template>

<style lang="scss">
.productSimilarProducts {
  & &__slider {
    padding: 0 18px;
  }

  & &__navigation {
    top: calc(
      ((100vw - 18px * 5) / 4) * (444 / 338 / 2) + var(--navigation-delta)
    );

    @include mq('lg') {
      top: calc(
        ((100vw - 18px * 3) / 2) * (444 / 338 / 2) + var(--navigation-delta)
      );
    }
  }
}
</style>
